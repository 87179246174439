import { Button, FieldGroup, GridCol, GridRow } from "@lmig/lmds-react";
import { User, useAppState } from "../../context/AppContext";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { getQuestionComponents } from "./VerificationQuestions";
import { apiGwService } from "../../services/api-gw-service";
import { Notification } from "@lmig/lmds-react";
import { useState, useEffect } from "react";
import ErrorNotification from "../../components/error/ErrorNotification";
import { ErrorComponent } from "../../components/error/ErrorComponent";
import { STRINGS } from "../../utilities/strings";
import Stepper from "../../components/stepper/Stepper";
import { stepLabels } from "../../components/stepper/steps-data";
import React from "react";
import InfoNotification from "../../components/notification/InformationNotification";
import { useAppStore } from "../../store/store";
import { PAGES } from "../../utilities/consts";
import { Address } from "../../store/store.types";

const generateQuestionComponents = (
  user: User | undefined,
  setFieldValue: (a: string, b: any) => {},
) => {
  let questionRender = [];
  const questionComponents = getQuestionComponents(setFieldValue);
  for (let question in user?.verificationQuestions) {
    questionRender.push(
      questionComponents[question as keyof typeof questionComponents],
    );
  }
  return questionRender;
};

const getInitialValues = (user: User | undefined) => {
  let initialValues: { [x: string]: string } = {};
  for (let question in user?.verificationQuestions) {
    initialValues[question as keyof typeof initialValues] = "";
  }
  return initialValues;
};

const setAnswers = (
  values: { [x: string]: string },
  user: User | undefined,
) => {
  if (user?.verificationQuestions) {
    for (let question in values) {
      let customerProvidedAnswer = values[question as keyof typeof values];
      if (question === "dateOfLoss") {
        const [month, day, year] = customerProvidedAnswer.split("/");
        customerProvidedAnswer = `${year}-${month}-${day}`;
      }
      user.verificationQuestions[question] = customerProvidedAnswer;
    }
  }
};

const countRetries = (user: User | undefined) => {
  if (user?.retryCount) {
    return 3 - user.retryCount;
  }
  return 3;
};

export const Verification = () => {
  const { state } = useAppState();
  const { user } = state;
  const [isVerificationPending, setIsVerificationPending] = useState(false);
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [displayNotification, setDisplayNotification] = useState(false);
  const [validationFailed, setValidationFailed] = useState(false);
  const [errorValidating, setErrorValidating] = useState(false);
  const { setPayeeAddress } = useAppStore();
  const { SELECT_PAYMENT_TYPE } = PAGES;

  useEffect(() => {
    if (user?.retryCount && user.retryCount >= 2) {
      setDisplayNotification(false);
      setShowError(true);
    } else if (showError) {
      setDisplayNotification(false);
    }
  }, [user?.retryCount, showError]);

  const handleOnSubmit = (values: { [x: string]: string }) => {
    setTimeout(() => {
      setDisplayNotification(false);
      setIsVerificationPending(true);
      setAnswers(values, user);
      apiGwService.validateData.validateData(user).then((res) => {
        if (res && !res.errorOccured) {
          if (res.verified === true) {
            console.log("Account data successfully verified");
            const payeeAddress = res.payeeAddress as unknown as Address;
            !!payeeAddress && setPayeeAddress(payeeAddress);
            navigate(`/${SELECT_PAYMENT_TYPE}`);
          } else {
            console.log("Account data could not be verified.");
            if (res.retryCount && user) {
              user.retryCount = res.retryCount;
            }
            if (res.retryAvailable) {
              setShowError(true);
            } else {
              console.log("Account data validation failed.");
              setValidationFailed(true);
            }
          }
        } else {
          console.log("Error or no response received from account validation.");
          setErrorValidating(true);
        }
        setIsVerificationPending(false);
      });
    }, 400);
  };

  const getRetriesHighlight = () => {
    if (user?.retryCount && user.retryCount >= 2) {
      return "negative";
    }

    return "neutral";
  };

  const getRetriesText = () => {
    if (user?.retryCount && user.retryCount >= 2) {
      return `Warning:Final Attempt.`;
    }
    if (showError) {
      setDisplayNotification(false);
    } else {
      setDisplayNotification(true);
    }
    return `${countRetries(user)} Attempts remaining`;
  };

  if (!validationFailed && !errorValidating) {
    return (
      <span className="verification">
        <Stepper activeStepLabel={stepLabels.IDENTITY_VERIFICATION} />
        <Formik
          initialValues={getInitialValues(user)}
          onSubmit={handleOnSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <GridRow gutters justify="center">
                <GridCol base={12} md={6}>
                  <FieldGroup
                    labelStyle="h3-light"
                    labelVisual="Please verify your identity."
                  >
                    <ErrorNotification
                      isError={showError}
                      alert={STRINGS.VALIDATION_ERROR}
                      message={STRINGS.INCORRECT_DATA}
                    />
                    <InfoNotification
                      displayed={displayNotification}
                      alert="Ensure Details Match Those Provided."
                      message="Please answer the below security questions to verify your identity."
                    />
                    <Notification
                      style={{ marginBottom: "10px" }}
                      alert={getRetriesText()}
                      highlightType={getRetriesHighlight()}
                    ></Notification>
                    {generateQuestionComponents(user, setFieldValue)}
                  </FieldGroup>
                </GridCol>
              </GridRow>
              <GridRow gutters justify="center">
                <GridCol base={6}>
                  <Button
                    dynamicWidth
                    variant="primary"
                    type="submit"
                    loading={isVerificationPending}
                  >
                    Next
                  </Button>
                </GridCol>
              </GridRow>
            </Form>
          )}
        </Formik>
      </span>
    );
  } else if (validationFailed) {
    return (
      <ErrorComponent
        title={STRINGS.VALIDATION_ERROR}
        alert={STRINGS.VALIDATION_FAILED}
        message={STRINGS.CONTACT_REP}
      />
    );
  } else {
    return (
      <ErrorComponent
        title={STRINGS.VALIDATION_ERROR}
        alert={STRINGS.VALIDATION_FAILED}
        message={STRINGS.TRY_AGAIN}
      />
    );
  }
};
