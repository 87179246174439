const PRODUCTION_HOST_NAME = "payments.libertymutual.com";
const TEST_HOST_NAME = "payments.libertymutual.com";
const DEVELOPMENT_HOST_NAME = "payments.libertymutual.com";
const LOCALHOST = "localhost";

const DEVELOPMENT_ENV_NAME = "development";

const PROPS: any = {
  "backend-url": [
    "https://dev.paymenthub-direct-deposit-enrollment-ui.lmig.com/api",
    `https://${DEVELOPMENT_HOST_NAME}/api`,
    `https://${TEST_HOST_NAME}/api`,
    `https://${PRODUCTION_HOST_NAME}/api`,
  ],
};

export const environmentProperties = {
  PRODUCTION_HOST_NAMES: [PRODUCTION_HOST_NAME],
  TEST_HOST_NAMES: [TEST_HOST_NAME],
  DEVELOPMENT_HOST_NAMES: [DEVELOPMENT_HOST_NAME],
  LOCALHOST: LOCALHOST,
  getAppProperty(name: string): string {
    const eid = environmentProperties.getEnvironmentId();
    return PROPS[name][eid];
  },

  getEnvironmentId(): number {
    if (environmentProperties._isProduction()) {
      return 3;
    } else if (environmentProperties._isTest()) {
      return 2;
    } else if (environmentProperties._isDevelopment()) {
      return 1;
    } else if (environmentProperties._isLocalhost()) {
      return 0;
    }
    throw new Error("Failed to determine environment id.");
  },
  getEnvironmentName(): string {
    if (environmentProperties._isProduction()) {
      return "production";
    } else if (environmentProperties._isTest()) {
      return "test";
    } else if (environmentProperties._isDevelopment()) {
      return "development";
    } else if (environmentProperties._isLocalhost()) {
      return "development";
    }
    throw new Error("Failed to determine environment name.");
  },
  _isProduction(): boolean {
    return (
      environmentProperties.PRODUCTION_HOST_NAMES.indexOf(
        environmentProperties._getHostName(),
      ) > -1
    );
  },
  _isTest(): boolean {
    return (
      environmentProperties.TEST_HOST_NAMES.indexOf(
        environmentProperties._getHostName(),
      ) > -1
    );
  },
  _isDevelopment(): boolean {
    return (
      environmentProperties.DEVELOPMENT_HOST_NAMES.indexOf(
        environmentProperties._getHostName(),
      ) > -1
    );
  },
  _isLocalhost(): boolean {
    return (
      environmentProperties._getHostName() === environmentProperties.LOCALHOST
    );
  },
  // When running app locally in HTTPS mode, neither actual endpoints nor MSW mock endpoints will be available
  _isLocalHttps(): boolean {
    return (
      process.env.NODE_ENV === DEVELOPMENT_ENV_NAME &&
      !(!process.env.REACT_APP_USE_MSW || !process.env.REACT_APP_PLAYWRIGHT)
    );
  },
  _getHostName(): string {
    return window.location.hostname.toLowerCase();
  },
};
